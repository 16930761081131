<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card title="Career Placement Partners List">
          <b-card-text>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="text-center">Sl</th>
                    <th class="text-center">Logo</th>
                    <th>Name</th>
                    <th class="text-center">Status</th>
                    <th class="text-center" v-if="permission.edit">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(partner, index) in partners.data"
                    :key="index"
                    v-if="partners.data.length"
                  >
                    <td class="align-middle text-center">
                      {{ partners.from + index }}
                    </td>
                    <td class="align-middle text-center">
                      <img
                        height="24"
                        :src="$store.state.base_url + partner.logo"
                        alt=""
                      />
                    </td>
                    <td><b></b>{{ partner.name }}</td>
                    <td class="align-middle text-center">
                      <span
                        class="badge"
                        :class="
                          partner.status == 1 ? 'badge-success' : 'badge-danger'
                        "
                      >
                        {{ partner.status == 1 ? "Active" : "Inactive" }}
                      </span>
                    </td>
                    <td class="align-middle text-center" v-if="permission.edit">
                      <button
                        class="btn btn-primary btn-sm"
                        @click="edit(partner.id)"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination
                :data="partners"
                @pagination-change-page="getResults"
                align="center"
                :show-disabled="true"
                :limit="5"
              ></pagination>
            </div>
          </b-card-text>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card
          :title="
            (form.id == '' ? 'Create' : 'Update') + ' Career Placement Partner'
          "
        >
          <b-card-text
            ><validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row">
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Name" label-for="name">
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          :state="errors.length > 0 ? false : null"
                          name="name"
                          placeholder="Enter Your Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <div class="d-block" style="height: 350px">
                      <b-form-group label="Description" label-for="description">
                        <validation-provider
                          #default="{ errors }"
                          name="description"
                        >
                          <!-- :partners="snowpartner" -->
                          <quill-editor
                            id="description"
                            style="height: 220px"
                            class="mb-4"
                            v-model="form.description"
                            name="description"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Your Description"
                          />
                          <div style="clear: both"></div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="border rounded p-2 mb-2">
                      <b-img
                        :src="logo"
                        height="24"
                        width="116"
                        class="rounded mr-2 mb-1 mb-md-0"
                      />

                      <b-form-file
                        class="mt-1"
                        id="logo"
                        accept=".jpg, .png, .gif"
                        @change="loadImage($event)"
                        placeholder="Select Logo"
                        drop-placeholder="Drop file here..."
                      />
                    </div>
                  </div>
                </div>
                <!-- submit buttons -->
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer></b-card-text
          >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        page: 1,
      },
      form: {
        id: "",
        partner_category_id: null,
        name: "",
        description: "",
        status: true,
        logo: "",
      },
      logo:
        this.$store.state.base_url +
        "images/career_placement_partner/default.png",
      partners: {},
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getResults();
    this.Permission();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const partners = await this.callApi(
        "post",
        "/app/career/placement/partner?page=" + this.search.page,
        this.search
      );
      if (partners.status == 200) {
        this.partners = partners.data.partners;
      }
    },

    loadImage(e) {
      this.form.logo = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.logo = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Close() {
      this.form.id = "";
      this.form.name = "";
      this.form.description = "";
      this.form.status = true;
      this.form.logo = "";
      this.logo =
        this.$store.state.base_url +
        "images/career_placement_partner/default.png";
    },

    async edit(id) {
      const partner = await this.callApi(
        "post",
        "/app/career/placement/partner/show/" + id
      );
      if (partner.status == 200) {
        this.form.id = partner.data.partner.id;
        this.form.name = partner.data.partner.name;
        this.form.description = partner.data.partner.description;
        this.form.status = partner.data.partner.status == true ? true : false;
        this.logo = this.$store.state.base_url + partner.data.partner.logo;
      }
    },

    add() {
      const fd = new FormData();
      if (this.form.logo) {
        fd.append("logo", this.form.logo, this.form.logo.name);
      }
      fd.append("id", this.form.id);
      fd.append("name", this.form.name);
      fd.append("description", this.form.description);
      fd.append("status", this.form.status);

      axios
        .post("/app/career/placement/partner/add", fd)
        .then((res) => {
          this.getResults();
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.logo)
              this.e(e.response.data.errors.logo[0]);
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
            if (e.response.data.errors.status)
              this.e(e.response.data.errors.status[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "career.placement.partner.access") {
          this.permission.access = true;
        }

        if (permission.slug == "career.placement.partner.create") {
          this.permission.create = true;
        }

        if (permission.slug == "career.placement.partner.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "career.placement.partner.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  components: {
    quillEditor,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
  computed: {},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
